<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Фильтры
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title">
              Статусы
            </h6>
            <b-form-checkbox-group
              v-model="filterStatuses"
              class="categories-radio-group"
              stacked
              value-field="id"
              text-field="name"
              :options="signalStatusSelect"
            />
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BCard,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState({
      signalStatusSelect: state => state.signalStatus.signalStatusSelect,
    }),
    filterStatuses: {
      get() {
        return this.$store.state.signal.filersStatuses
      },
      set(v) {
        return this.setFilersStatuses(v)
      },
    },
  },
  watch: {
    filterStatuses() {
      this.getSignals()
    },
  },
  mounted() {
    this.signalStatuses()
  },
  methods: {
    ...mapMutations({
      setFilersStatuses: 'signal/setFilersStatuses',
    }),
    ...mapActions({
      getSignals: 'signal/getSignals',
      signalStatuses: 'signalStatus/getSignalStatusSelect',
    }),
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.ecommerce-application .sidebar-shop .filter-title{
  margin-top: 7px;
}
</style>
